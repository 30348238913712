import appConfig from "@/configs";

const { authServiceFeUrlWithRedirect } = appConfig;

export async function getIdToken(): Promise<string> {
  const idTokenResp = await fetchIdToken();
  return idTokenResp.token as string;
}

async function fetchIdToken(): Promise<{ token: string }> {
  try {
    const resp = await fetch(
      `${process.env.NEXT_PUBLIC_AUTH_SERVICE_DOMAIN}/token/refresh`,
      {
        credentials: "include",
      }
    );

    if (!resp.ok) {
      window.location.href = authServiceFeUrlWithRedirect;
      throw new Error("Failed to refresh id token");
    }

    return resp.json();
  } catch (err) {
    console.error("Error fetching id token: ", err);
    throw err;
  }
}

export default getIdToken;
